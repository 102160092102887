import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { register } from "actions/auth";
import { NotificationManager } from 'react-notifications';

export interface PageSignUpProps {
  className?: string;
}

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState('')
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [phone, setPhone] = useState('')

  const history = useHistory();

  const handleSignUp = async () => {
    let user = {
      name: name,
      lastname: lastname,
      email: email,
      password: password,
      phone: phone,
      termsPrivacyPolicy: true
    }

    await dispatch(register(user)).then(() => {
      history.replace('/');
    })
      .catch((err: any) => {
        NotificationManager.error(err.response.data.error, '');
      })

  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      handleSignUp();
    }
  };

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Créer un compte | Alcools Bio</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Créer un compte
        </h2>

        <div className="max-w-md mx-auto space-y-6 ">

          {/* FORM */}
          <div className="grid grid-cols-2 gap-6">
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Prénom
              </span>

              <Input
                type="text"
                onChange={(e) => setName(e.target.value)}
                placeholder="John"
                onKeyDown={handleKeyDown}
                className="mt-1"
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Nom
              </span>

              <Input
                type="text"
                onKeyDown={handleKeyDown}
                placeholder="Doe"
                className="mt-1"
                onChange={(e) => setLastname(e.target.value)}
              />
            </label>
          </div>
          <div className="grid grid-cols-1">
            <label className="block mb-6">
              <span className="text-neutral-800 dark:text-neutral-200">
                Numéro de téléphone
              </span>
              <span className="text-[red] ml-1">*</span>

              <Input
                onChange={(e) => setPhone(e.target.value)}
                type="text"
                onKeyDown={handleKeyDown}
                placeholder="+33"
                className="mt-1"
              />
            </label>

            <label className="block mb-6">
              <span className="text-neutral-800 dark:text-neutral-200">
                Adresse email
              </span>
              <span className="text-[red] ml-1">*</span>

              <Input
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                onKeyDown={handleKeyDown}
                placeholder="example@example.com"
                className="mt-1"
              />
            </label>

            <label className="block mb-6">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Mot de passe
              </span>
              <Input
                type="password"
                className="mt-1"
                onKeyDown={handleKeyDown}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={'**********'}
              />
              <span className="text-slate-500 text-xs italic">*Minimum 1 majuscule et 1 caractère spécial</span>

            </label>
            <ButtonPrimary onClick={() => handleSignUp()}>Créer un compte</ButtonPrimary>

            <p className="text-slate-500 text-[14px] text-center mt-3 mb-5">En continuant, vous acceptez les <a onClick={() => window.location.href = "/cgv"} target="_blank" className="underline hover:text-[#234336] cursor-pointer">conditions d'utilisation</a> du site.</p>
          </div>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Avez-vous déjà un compte? {` `}
            <Link className="text-green-600 hover:underline whitespace-normal inline-block" to="/login">
              Connectez-vous
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
