import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Contact = () => {

    return (
        <div className="nc-PageHome relative overflow-hidden">
            <Helmet>
                <title>Contact - Alcools Bio</title>
            </Helmet>

            <div className="bg-[#134435] py-2 text-[white]">
                <div className="flex flex-row container lg:px-16 xl:px-16 md:px-16">
                    <Link to="/" className="underline cursor-pointer font-[500]">Accueil</Link>
                    <span className="ml-2 mr-2">{">"}</span>
                    <h1>Nous contacter</h1>
                </div>
            </div>

            <div className="bg-[white]">
                <div className={`container`}>
                    <div className={`flow-root py-4`}>
                        <div className="flex text-3xl lg:px-36 justify-center flex-col py-14">
                            <h1 className="text-[#725E39] font-semibold text-[40px] flex justify-center">Nous contacter</h1>

                            <div className="mt-14">
                                <div className="text-center">
                                    <h1 className="text-[#13443e] text-[32px]">Une question, un renseignement ou besoin d'aide ?</h1>
                                    <div className="mt-4 text-[15px] leading-normal">
                                        <h3><a className="hover:underline hover:font-[500]" href="mailto:contact@alcools-bio.fr">contact@alcools-bio.fr</a> ou <a href="tel:+33658541387" className="hover:underline hover:font-[500]">06 58 54 13 87</a>  (du lundi au vendredi de 9h à 17h)</h3>
                                    </div>

                                    <div className="flex h-full w-full mt-10">
                                    <iframe className="flex w-full" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2802.1811695835027!2d-0.2601669!3d45.3855161!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48006970da8e0c5b%3A0x2804f62ad231212d!2s3%20Rte%20du%20Molin%2C%2016360%20Baignes-Sainte-Radegonde!5e0!3m2!1sfr!2sfr!4v1694703539610!5m2!1sfr!2sfr" loading="lazy" style={{ height: '500px' }}></iframe>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default Contact;
