import { FC, Fragment, useEffect, useId, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { getAllCategoriesFlat } from "actions/categories";
import { IMG_LINK } from "config/key";
import F1 from "images/f1.png";
import F2 from "images/f2.png";

export interface CardCategoryData {
  name: string;
  desc: string;
  img: string;
  color?: string;
}

export interface SectionSliderCategoriesProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  data?: CardCategoryData[];
}

const SectionSliderCategories: FC<SectionSliderCategoriesProps> = ({
  heading = "",
  className = "",
}) => {
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");
  const [categories, setCategories] = useState([])
  let history = useHistory();
  const [hoveredCategory, setHoveredCategory] = useState(null);

  useEffect(() => {
    getAllCategoriesFlat().then((result: any) => {
      setCategories(result);
    })
  }, [])

  return (
    <>
      {Object.keys(categories).length !== 0 &&
        <div className={`container ${className} mb-24`}>
          <div className={`${UNIQUE_CLASS} flow-root`}>
            <div className="flex text-3xl font-semibold justify-center mb-14 mt-14 flex-row">
              <div>
                <img src={F1} />
              </div>
              <h1 className="max-w-[50%] text-center mt-24 text-[#234336]">{heading}</h1>
              <div>
                <img src={F2} />
              </div>
            </div>

            <div className="flex grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-3">
              {categories.map((cat: any, index: any) => (

                <Fragment key={index}>

                  {cat?.homeCat === true &&
                    <div className="relative h-[400px] hidden md:block xl:block lg:block cursor-pointer overflow-hidden" onClick={() => history.push({ pathname: '/products', search: `?categories=${cat?.name}` })}>
                      <img className="w-full h-full absolute object-cover" src={IMG_LINK + cat?.imageId} />
                      <div className="opacity-0 text-center overflow-hidden hover:opacity-80 bg-[#fdf0dc] duration-300 absolute inset-0 z-10 flex flex-col justify-center items-center text-[28px] text-black font-bold">
                        <span>{cat?.name}</span>
                        <span className="lg:text-[18px] xs:text-[13px] mt-4 px-5" >{cat?.description}</span>
                      </div>
                    </div>
                  }

                  {cat?.homeCat === true && (
                    <div className="relative h-[400px] block lg:hidden md:hidden xl:hidden cursor-pointer"
                      onClick={() => history.push({ pathname: '/products', search: `?categories=${cat?.name}` })}
                    >
                      <img className="w-full h-full object-cover" src={IMG_LINK + cat?.imageId} />
                      <div className={`absolute flex w-full bg-[#fdf0dc] opacity-[.9] hover:opacity-[1] py-6 bottom-0 block`}>
                        <h1 className="mx-auto font-bold">{cat?.name} →</h1>
                      </div>
                    </div>
                  )}


                </Fragment>
              ))}


            </div>
          </div>
        </div>
      }
    </>
  );
};

export default SectionSliderCategories;
