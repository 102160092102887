import { Helmet } from "react-helmet";

const Cgv = () => {

    return (
        <div className="nc-PageHome relative overflow-hidden">
            <Helmet>
                <title>CGV | Alcools Bio</title>
            </Helmet>

            <div className="flex justify-center py-10">
                <div className="max-w-5xl p-4">
                    <div className="text-center">
                        <h1 className="text-3xl font-bold mb-4 uppercase">Conditions Générales de Vente de la page producteur "alcools-bio.fr"</h1>
                        <p className="mb-4">accessible depuis l’URL : www.alcools-bio.fr</p>
                    </div>

                    <div className="mb-4 mt-14">
                        La société VIVANT,<br />
                        Est inscrite au registre du commerce sous le numéro 839292141 RCS Angoulême<br />
                        Son adresse postale est : 6 chemin du But BP10027 16210 Chalais<br />
                        Son adresse de courrier électronique est : contact@alcools-vivant.com<br />
                        Toute prise de commande suppose la consultation et l’acceptation préalable des présentes conditions générales de vente. Le clic de validation de la commande implique une pleine acceptation des présentes. Ce clic à valeur de "signature numérique"<br />

                        <div className="mt-4">
                            <span className="font-semibold">LES PRIX</span><br />
                            <p className="mt-1">Nos prix s'entendent TTC, nets, sans escompte<br />
                                Toutes nos offres s'entendent dans la limite des stocks disponibles.
                            </p>
                        </div>

                        <div className="mt-4">
                            <span className="font-semibold">LA LIVRAISON</span><br />
                            <p className="mt-1">Tous nos produits sont vendus au départ de notre dépôt et sont assurés par notre prestataire de livraison.<br />
                                La livraison est effectuée par le transporteur au lieu de livraison que vous avez indiqué au moment de la commande.<br />
                                Vous êtes tenu de vérifier l'état des marchandises à la livraison et de signaler tout dommage, manquant ou erreur sur la facture. Vous nous adresserez votre réclamation par e-mail dans un délai de 48h suivant la réception des marchandises.<br />
                                Nous nous engageons à procéder à la livraison dans les meilleurs délais. Si la livraison ne pouvait être assurée, vous en seriez rapidement informé et pourriez annuler votre commande, demander l'échange du produit ou son remboursement.<br />
                                La livraison ne peut intervenir que si vous êtes à jour de vos obligations envers nous.</p>
                        </div>

                        <div className="mt-4">
                            <span className="font-semibold">LE PAIEMENT</span><br />
                            <p className="mt-1">Lors de votre commande, le règlement est exigé par carte bleue ou virement via notre site web.</p>
                        </div>

                        <div className="mt-4">
                            <span className="font-semibold">DROIT DE RÉTRACTATION</span><br />
                            <p className="mt-1">Une faculté de rétraction est offerte à l’acheteur en vertu de l’article L221-28 du code de la consommation.<br />
                                Dans un délai de 14 jours, à réception de la marchandise, le Client peut user de son droit de rétraction, il devra nous adresser par mail, courrier postal ou fax un courrier de rétractation.<br />
                                Après réception de la demande de rétraction, le Client qui use de cette faculté, aura l’obligation de procéder sous sa responsabilité et à ses frais, au retour de la marchandise.<br />
                                Le remboursement interviendra dans les 14 jours de la réception de la demande de remboursement. Ce remboursement pourra être différé jusqu'au retour des marchandises.</p>
                        </div>

                        <div className="mt-4">
                            <span className="font-semibold">RÉSERVE DE PROPRIÉTÉ</span><br />
                            <p className="mt-1">Jusqu'au paiement effectif et intégral du prix, nous restons propriétaire des biens vendus. Le complet règlement vaudra transfert de propriété. Toutefois l'acheteur devient responsable des articles dès la livraison, le transfert de possession valant transfert de risques.</p>
                        </div>

                        <div className="mt-4">
                            <span className="font-semibold">PROTECTION DES MINEURS</span><br />
                            <p className="mt-1">En vertu de l'ordonnance n°59-107 du 7 janvier 1959 et de la loi de juillet 1974, la vente d'alcool en France est interdite aux personnes mineures de moins de 18 ans. En conséquence, l'acheteur s'engage à avoir 18 ans révolus le jour de la passation de la commande.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Cgv;
