import React, { FC, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import Prices from "./Prices";
import { ArrowsPointingOutIcon } from "@heroicons/react/24/outline";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import toast from "react-hot-toast";
import { Transition } from "@headlessui/react";
import ModalQuickView from "./ModalQuickView";
import { Product } from "../models/Product"
import PlaceIcon from "shared/NcImage/PlaceIcon";
import { addProductInWishlist, removeProductInWishlist } from "actions/wishlist";
import { useDispatch, useSelector } from "react-redux";
import { IMG_LINK } from "config/key";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface ProductCardProps {
  className?: string;
  data: Product;
  refreshFnc?: Function
}

const ProductCard: FC<ProductCardProps> = ({
  className = "",
  data,
  refreshFnc
}) => {
  const {
    name,
    price,
    Variants,
    images,
    _id,
    defaultVariantId,
  } = data;

  const [showModalQuickView, setShowModalQuickView] = React.useState(false);
  const history = useHistory();
  const priceVariant: any = [];
  const dispatch = useDispatch();
  const { wishlist }: any = useSelector(state => state);
  const { auth }: any = useSelector(state => state)
  const [defaultVariant, setDefaultVariant] = useState<any>([]);
  const [dataQuickView, setDataQuickView] = useState<any>([]);

  useEffect(() => {

    var defaultVariant = Variants?.find((item: any) => item._id == defaultVariantId);
    setDefaultVariant(defaultVariant);

  }, [refreshFnc])

  const notifyAddTocart = ({ size }: { size?: string }) => {
    toast.custom(
      (t) => (
        <Transition
          appear
          show={t.visible}
          className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
          enter="transition-all duration-150"
          enterFrom="opacity-0 translate-x-20"
          enterTo="opacity-100 translate-x-0"
          leave="transition-all duration-150"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 translate-x-20"
        >
          <p className="block text-base font-semibold leading-none">
            Ajouter au panier!
          </p>
          <div className="border-t border-slate-200 dark:border-slate-700 my-4" />
          {renderProductCartOnNotify({ size })}
        </Transition>
      ),
      { position: "top-right", id: "nc-product-notify", duration: 3000 }
    );
  };

  const renderProductCartOnNotify = ({ size }: { size?: string }) => {
    return (
      <div className="flex ">
        <div className="h-20 w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          {images?.image1Id

            ? <NcImage
              containerClassName="flex aspect-w-11 aspect-h-12 w-full h-0"
              src={IMG_LINK + images?.image1Id}
              className="object-cover w-full h-full drop-shadow-xl"
            />

            : <div className="nc-NcImage flex aspect-w-11 aspect-h-12 w-full h-0">
              <div className={`${className} flex items-center justify-center bg-neutral-200 dark:bg-neutral-6000 text-neutral-100 dark:text-neutral-500`}>
                <div className="h-2/4 max-w-[50%]">
                  <PlaceIcon />
                </div>
              </div>
            </div>
          }
        </div>

        <div className="ml-4 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div>
                <h3 className="text-base font-medium ">{name}</h3>
                <p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
                  {/* <span>
                    {variants ? variants[variantActive].name : `Natural`}
                  </span> */}
                  <span className="mx-2 border-l border-slate-200 dark:border-slate-700 h-4"></span>
                  <span>{data?.longDescription}</span>
                </p>
              </div>
              <Prices price={price} className="mt-0.5 whitespace-nowrap" />
            </div>
          </div>
          <div className="flex flex-1 items-end justify-between text-sm">
            <p className="text-gray-500 dark:text-slate-400">Qty 1</p>

            <div className="flex">
              <button
                type="button"
                className="font-medium text-primary-6000 dark:text-primary-500 "
                onClick={(e) => {
                  e.preventDefault();
                  history.push("cart");
                }}
              >
                Voir panier
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderVariants = () => {


    if (Variants && Array.isArray(Variants) && Variants.length > 0) {
      let arr: any = [];
      let propArr: any = {};

      Variants.map((variant) => {
        priceVariant.push(variant.price);

        if (variant?.properties) {
          Object.keys(variant?.properties).map((prop) => {
            if (!arr.includes(prop))
              arr.push(prop)
          })
        }
      })

      arr.map((propertieName: string) => {
        propArr[propertieName] = []
      })

      arr.map((propertieName: string) => {
        Variants.map((variant) => {
          Object.keys(variant.properties).map((it) => {
            if (propertieName === it) {
              propArr[it].push(variant.properties[it])
            }
          })
        })
      })

      for (const propertie in propArr) {
        let uniqueChars = propArr[propertie].filter((element: any, index: any) => {
          return propArr[propertie].indexOf(element) === index;
        });

        propArr[propertie] = uniqueChars
      }



      // DISPLAY VARIANT ON MINIATURE PRODUCT #1
      return (
        <>
          {Object.keys(propArr).length !== 0 &&

            <div className="flex flex-row rounded-xs bg-[#208bc9] text-[white] px-2 py-[1.5px] mt-[.6rem] rounded shadow-xl">
              {Object.keys(propArr).map((key, index) => (
                <div key={index} className="">
                  <div className="text-[11px] text-white font-[500]">
                    <span>{propArr[key].length}{' '}{key}</span>
                    <span className={`${(index === Object.keys(propArr).length - 1) ? "hidden" : "ml-1 mr-1"}`}>-</span>
                  </div>
                </div>
              ))}
            </div>
          }
        </>

      )
    }
  }

  const handleAddProductWishlist = async (itemId: any) => {
    if (!wishlist.hasOwnProperty(_id)) {
      await dispatch(addProductInWishlist(itemId));
    } else {
      await dispatch(removeProductInWishlist(itemId)).then(() => {
        if (refreshFnc) {
          refreshFnc()
        }
      })
    }
  }

  const handleQuickViewProduct = (data: Product) => {
    if (!showModalQuickView) {
      setShowModalQuickView(true)
    }

    setDataQuickView(data)
  }

  const minPrice = Variants && Math.min(...Variants.map(product => product?.rebatedPrice || product.price));
  const maxPrice = Variants && Math.max(...Variants.map(product => product.price));

  return (
    <>
      <div
        className={`nc-ProductCard relative flex flex-col bg-transparent ${className} bg-[white] p-1 shadow-lg hover:shadow-lg mb-6`}
        data-nc-id="ProductCard"
      >
        <Link to={{ pathname: "/product-detail/" + data.slug }} className="absolute inset-0"></Link>

        <div className="relative flex-shrink-0 bg-slate-50 dark:bg-slate-300 overflow-hidden z-1 group">

          <Link to={{ pathname: "/product-detail/" + data.slug }} className="block">
            {defaultVariant?.images?.thumbnailId

              ? <div className="flex aspect-w-11 aspect-h-12 w-full h-0">
                <img
                  src={IMG_LINK + defaultVariant?.images?.thumbnailId}
                  className="object-cover w-full h-full drop-shadow-xl"
                />
              </div>

              : <div className="nc-NcImage flex aspect-w-11 aspect-h-12 w-full h-0">
                <div className={`${className} flex items-center justify-center bg-neutral-200 dark:bg-neutral-6000 text-neutral-100 dark:text-neutral-500`}>
                  <div className="h-2/4 max-w-[50%]">
                    <PlaceIcon />
                  </div>
                </div>
              </div>
            }
          </Link>

          <div className="absolute bottom-0 group-hover:bottom-4 inset-x-1 flex justify-center opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all">

            <ButtonSecondary
              className="ml-1.5 transition-colors shadow-lg"
              fontSize="text-xs"
              sizeClass="py-2 px-4"
              onClick={() => handleQuickViewProduct(data)}
            >
              <ArrowsPointingOutIcon className="w-3.5 h-3.5" />
              <span className="ml-1">Aperçu rapide</span>
            </ButtonSecondary>
          </div>

          {auth?.token !== undefined &&
            <>
              {auth?.token !== null &&
                <div onClick={() => handleAddProductWishlist(data?._id)} className="flex absolute top-1 right-1 p-2">
                  <button className={`w-9 h-9 flex items-center justify-center rounded-full bg-white dark:bg-slate-900 text-neutral-700 dark:text-slate-200 nc-shadow-lg ${className}`}>
                    <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M12.62 20.81C12.28 20.93 11.72 20.93 11.38 20.81C8.48 19.82 2 15.69 2 8.68998C2 5.59998 4.49 3.09998 7.56 3.09998C9.38 3.09998 10.99 3.97998 12 5.33998C13.01 3.97998 14.63 3.09998 16.44 3.09998C19.51 3.09998 22 5.59998 22 8.68998C22 15.69 15.52 19.82 12.62 20.81Z"
                        stroke={wishlist.hasOwnProperty(data._id) ? 'red' : "currentColor"}
                        fill={wishlist.hasOwnProperty(data._id) ? 'red' : "white"}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              }
            </>
          }
        </div>

        <hr className="mt-3 mb-3" />

        <div className="space-y-2 px-2.5 pb-2.5">
          <div>
            <h2
              className={`nc-ProductCard__title text-base font-[500] transition-colors text-center`}
            >
              {name}
            </h2>




            <div className="">

              {/* <div>
                {defaultVariant?.volume
                  ? <>
                    {defaultVariant?.volume + "L"} 
                  </>
                  : defaultVariant?.netWeight
                    ? <>
                      {defaultVariant?.netWeight >= 1000 ? (defaultVariant?.netWeight / 1000).toString() + " kg" : defaultVariant?.netWeight + " g"}
                    </>
                    : <>
                      {defaultVariant?.weight >= 1000 ? (defaultVariant?.weight / 1000).toString() + " kg" : defaultVariant?.weight + " g"}

                    </>
                }
              </div> */}

              <div className="mb-4 flex justify-center">
                {Variants &&
                  <div className="flex flex-row">
                    {data.simpleItem === false
                      ? <div className="flex flex-row justify-center mt-4">
                        <span className="text-[13px] text-center">À partir de</span>
                        <Prices price={minPrice} />
                        {"-"}
                        <Prices price={maxPrice} />
                      </div>
                      : <>
                        {defaultVariant?.rebatedPrice
                          ? <>
                            <Prices
                              price={defaultVariant.rebatedPrice}
                            />

                            <Prices
                              className="text-[red]"
                              contentClass="py-1 md:py-1.5 md:px-3 text-lg font-[500] line-through text-red-500"
                              price={defaultVariant.price}
                            />
                          </>

                          : <Prices
                            price={defaultVariant.price}
                          />
                        }
                      </>
                    }


                  </div>
                }
              </div>
            </div>



          </div>

          <div className="flex w-full mt-5">
            <ButtonPrimary
              className="shadow-lg flex w-full"
              fontSize="text-sm"
              sizeClass="py-[8px] px-4"
              onClick={() => history.push({ pathname: '/product-detail/' + data?.slug })}
            >
              <div className="font-semibold">
                <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="18px" height="18px" viewBox="0 0 32 32">
                  <path d="M 19 3 C 13.488281 3 9 7.488281 9 13 C 9 15.394531 9.839844 17.589844 11.25 19.3125 L 3.28125 27.28125 L 4.71875 28.71875 L 12.6875 20.75 C 14.410156 22.160156 16.605469 23 19 23 C 24.511719 23 29 18.511719 29 13 C 29 7.488281 24.511719 3 19 3 Z M 19 5 C 23.429688 5 27 8.570313 27 13 C 27 17.429688 23.429688 21 19 21 C 14.570313 21 11 17.429688 11 13 C 11 8.570313 14.570313 5 19 5 Z M 18 9 L 18 12 L 15 12 L 15 14 L 18 14 L 18 17 L 20 17 L 20 14 L 23 14 L 23 12 L 20 12 L 20 9 Z" />
                </svg>
              </div>
              <span className="ml-2">Découvrir</span>
            </ButtonPrimary>
          </div>

        </div>
      </div>

      {/* QUICKVIEW */}
      <ModalQuickView
        data={dataQuickView}
        show={showModalQuickView}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
      />
    </>
  );
};

export default ProductCard;
