import React, { FC } from "react";
import HIW1 from "images/icon_1_hiw.png";
import HIW2 from "images/icon_2_hiw.png";
import HIW3 from "images/icon_3_hiw.png";
import HIW4 from "images/icon_4_hiw.png";
import { COLORS } from "config/theme";

export interface SectionHowItWorkProps {
  className?: string;
  data?: typeof DEMO_DATA[0][];
}

const themeColor = '#234336'

const DEMO_DATA = [
  {
    id: 1,
    icon: HIW1,
    title: "Spiritueux bio 100% Français",
  },
  {
    id: 2,
    icon: HIW2,
    title: "Paiement sécurisé",
  },
  {
    id: 3,
    icon: HIW3,
    title: "Livraisons rapide partout en France",
  },
  {
    id: 4,
    icon: HIW4,
    title: "Service client basé en France",
  },
];

const SectionHowItWork: FC<SectionHowItWorkProps> = ({
  className = "",
  data = DEMO_DATA,
}) => {
  return (
    <div
      className={`nc-SectionHowItWork container ${className}`}
      data-nc-id="SectionHowItWork"
    >

      <div style={{ backgroundColor: COLORS?.backgroundHowItWork }} className="flex grid gap-8 lg:grid-cols-4 xs:grid-cols-2 md:grid-cols-4 sm:grid-cols-2 items-center justify-center py-14 md:py-0 lg:py-0 xl:py-0">
        {data.map((item: typeof DEMO_DATA[number], index: number) => (
          <div key={index} className="flex justify-center items-center">
            <div className="mr-4">
              <img className="hitImg" src={item?.icon} />
            </div>
            <div className={`max-w-[145px] text-[${themeColor}] font-[500]`}>
              {item?.title}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionHowItWork;
