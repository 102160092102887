
import { Helmet } from "react-helmet";
import i1 from "../../images/alcools-bio-de-france-01.png";
import i2 from "../../images/verre.png";
import { Link } from "react-router-dom";
import SectionBeforeFooter from "components/SectionBeforeFooter";

const NosAlcoolsBioFrançais = () => {

    return (
        <div className="nc-PageHome relative overflow-hidden">
            <Helmet>
                <title>Nos Alcools Bio Français - Alcools Bio Français</title>
            </Helmet>

            <div className="bg-[#134435] py-2">
                <div className="flex flex-row container lg:px-16 xl:px-16 md:px-16 text-[white]">
                    <Link to="/" className="underline cursor-pointer font-[500]">Accueil</Link>
                    <span className="ml-2 mr-2">{">"}</span>
                    <h1>Nos alcools BIO français</h1>
                </div>
            </div>
            <div className="bg-[white]">
                <div className={`container`}>
                    <div className={`flow-root`}>
                        <div className="flex text-3xl lg:px-36 justify-center flex-col py-14">

                            <div>
                                <h1 className="text-[#725E39] font-semibold text-[40px]">Nos alcools BIO Français</h1>
                            </div>


                            <div className="bg-[#f3f5f3] px-6 w-full h-full grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-8 xl:grid-cols-3 mt-8 h-full">
                                <div className="flex justify-center h-full items-center">
                                    <img className="flex object-cover lg:h-1/2 md:lg:h-1/2 xl:lg:h-1/2 items-center" src={i1} />
                                </div>
                                <div className="col-span-2 flex items-center">
                                    <div className="md:py-4 lg:py-4 xl:py-4">
                                        <h1 className="mb-2 text-xl">Une démarche engagée et durable</h1>
                                        <h2 className="mb-4 text-lg font-[550]">Whisky, rhum, gin, cognac : la révolution bio et locale est en marche !</h2>

                                        <p className="mb-4 text-sm">Certifiés agriculture biologique, nos spiritueux sont issus d’un artisanat de haute exigence et rivalisent de qualité avec les marques les plus prestigieuses.<br /><br />

                                            Loin du bling-bling marketing et des emballages tape-à-l’œil, ils tirent leur supplément d’âme d’une démarche de sobriété et de respect de la nature, de la production agricole jusqu’à l’embouteillage. Nos marques s’appuient sur des filières agricoles biologiques développées pour leurs besoins spécifiques. Elles mènent une réflexion de fond sur leurs packagings et leurs procédés de fabrication.<br /> <br />

                                            Elles veillent à s’approvisionner en France pour tout ce qui peut y être produit, dans les réseaux équitables pour ce qui ne le peut pas, et établissent avec leurs partenaires des échanges équitables…<br /><br />

                                            Si l’abus d’alcool peut nuire à la santé, ces spiritueux n’auront pas infligé de dommages à la terre et auront généré une activité dignement rétribuée, ici comme ailleurs.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <img className="mt-4" src={i2} />

                            <div className="mt-10">
                                <h1 className="text-[#0e3d30] text-[37px]">VIVANT : spiritueux rares, bio et sans additifs</h1>

                                <div className="text-[14px] leading-[25px]">
                                    <div className="mt-6">
                                        <a target="_blank" className="text-[#756969] font-semibold hover:text-[#0e3d30]" href="https://alcools-vivant.com">www.alcools-vivant.com</a>
                                    </div>


                                    <div className="mt-6">
                                        <h1 className="font-semibold">Local</h1>
                                        <p>Basé dans le sud de la Charente, en Nouvelle Aquitaine, VIVANT a développé son activité sur la base de partenariats de proximité : fermes, malterie, distillateur, tonnelier, stockeur, embouteilleur, imprimeurs... Une façon de privilégier la relation directe tout en diminuant l'empreinte carbone liée aux transports.</p>
                                    </div>

                                    <div className="mt-6">
                                        <h1 className="font-semibold">Écoresponsable</h1>
                                        <p>VIVANT sélectionne ses bouteilles et éléments de packagings en fonction de leur bilan écologique, depuis leur production jusqu'à leur recyclage, réutilisation ou éventuelle destruction. Avec une volonté de constante amélioration, la marque reste à l'affût de solutions alternatives plus propres, plus économes en ressources, plus vertueuses. Dans son activité quotidienne, Vivant est aussi dans une démarche de sobriété : déplacements optimisés, de préférence en train, matériels d'occasion, choix low-tech...</p>
                                    </div>

                                    <div className="mt-6">
                                        <h1 className="font-semibold">Social & éthique</h1>
                                        <p>La fluidité des relations, la transparence et la responsabilité sociale sont pour VIVANT des critères essentiels à toutes les étapes : agriculture, transformation, transports, conception, création, design, packaging, embouteillage, distribution...</p>
                                        <p className="mt-3">Pour les matières agricoles, nous nous inscrivons dans une démarche de commerce équitable – soit en nous approvisionnant dans des filières certifiées, soit en développant des relations directes avec des producteurs. Nous nous engageons à leurs côtés sur plusieurs années et leur garantissons l'achat de leurs récoltes à prix avantageux.</p>
                                    </div>

                                    <div className="mt-6">
                                        <h1 className="font-semibold">Zéro additif</h1>
                                        <p>C'est pour VIVANT une évidence. Même si ces pratiques sont autorisées et courantes, nos spiritueux ne font l'objet d'aucun ajout de sucres, colorants, décolorants, arômes alimentaires, agents texturants, antioxydants, ou fixateurs de couleur. Les produits Vivant sont francs et honnêtes ; ils ne trichent pas. A ce titre, ils peuvent parfois présenter un léger trouble qui n'est qu'un signe de leur qualité artisanale.</p>
                                    </div>

                                    <div className="mt-6">
                                        <h1 className="font-semibold">Des produits d'exception</h1>
                                        <p>Les produits VIVANT sont issus de matières agricoles d'une qualité sans reproche ; élaborés par des artisans de haut vol ; vieillis, assemblés et réduits sans recours aux facilités et séductions des additifs alimentaires ; portés par un esprit de responsabilité sociale et une volonté de réparation de la nature. Autant de points qui font des des spiritueux Vivant des produits d'exception.</p>
                                    </div>


                                </div>
                            </div>

                            <div className="bg-[#f3f5f3] px-6 w-full grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-8 xl:grid-cols-3 mt-8 h-full">
                                <div className="flex justify-center">
                                    <img src={i1} />
                                </div>
                                <div className="col-span-2 flex items-center">
                                    <p className="text-[14px] mb-10 lg:mb-0 md:mb-0 xl:mb-0 text-[#0e3d30] leading-[25px]">
                                        Nos marques sont distribuées chez de nombreux cavistes sensibilisés au « bio et nature » et en magasins spécialisés bio. Les prix que nous pratiquons sont comparables aux leurs. Cette plateforme a pour but de fournir les consommateurs éloignés de tels points de vente. Ou ceux dont les points de vente n’ont pas encore senti venir le vent d’un inéluctable changement d’époque et de pratiques !
                                    </p>
                                </div>
                            </div>

                        </div>
                        
                    </div>
                </div>
            </div>

        </div>
    );
}

export default NosAlcoolsBioFrançais;
