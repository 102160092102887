import React, { FC } from "react";

export interface PricesProps {
  className?: string;
  price?: number;
  contentClass?: string;
}

const Prices: FC<PricesProps> = ({
  className = "",
  price,
  contentClass = "py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-[500]",
}) => {
  return (
    <div className={`${className}`}>
      {price &&

        <div
          className={`flex items-center rounded-lg ${contentClass}`}
        >
          <span className="text-[16px] !leading-none">
            {price?.toFixed(2)} &euro;
          </span>
        </div>
      }

    </div>
  );
};

export default Prices;
