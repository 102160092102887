import { LOGIN_SUCCESS, REGISTER_SUCCESS, LOGOUT, GET_CART, GET_WISHLIST, USER_INFO_SUCCESS } from "./types";
import api from "../api/auth";
import { User } from "models/User";

// LOGIN USER
export const login = (email: string, password: string): any =>
    

    async (dispatch: any) => {
        return api
            .login({ password, email })
            .then(async (result: any) => {

                if (result.data) {
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: {
                            token: result.data.token,
                            user: result.data
                        },
                    });

                    localStorage.setItem('token', result.data.token);

                    return true
                }
            })
    };

// LOGOUT USER
export const logout = (): any =>

    async (dispatch: any) => {
        return api
            .logout()
            .then(async (result: any) => {

                if (result.data) {
                    localStorage.removeItem('token');

                    dispatch({ type: LOGOUT });

                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: {
                            token: null,
                            user: null
                        },
                    });

                    dispatch({
                        type: USER_INFO_SUCCESS,
                        payload: null
                    });

                    dispatch({
                        type: GET_CART, payload: null
                    });

                    dispatch({
                        type: GET_WISHLIST, payload: null
                    });
                }
            })

            .catch(async (err: any) => {
                console.log(err)
            });
    };

// REGISTER USER
export const register = (user: User): any =>

    async (dispatch: any) => {
        return api
            .register(user)
            .then(async (result: any) => {

                if (result.data) {
                    localStorage.setItem('token', result.data.token);

                    dispatch({
                        type: REGISTER_SUCCESS,
                        payload: {
                            token: result.data.token,
                            user: result.data
                        },
                    });

                    return true
                }
            })
    };

// FORGOT PASSWORD MAIL
export const forgotPasswordMail = async (email: string): Promise<any> => {
    let result = await api
        .forgotPasswordMail(email)
        .then(async (result: any) => {
        })
};

// RESET PASSWORD
export const resetPassword = async (email: string, id: string): Promise<any> => {
    let result = await api
        .resetPasswordMail(email, id)
        .then(async (result: any) => {
        })

};