import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { COLORS } from "config/theme";
import { FC, Fragment, useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { LocationStates } from "routers/types";

export interface NavItemType {
  id: string;
  name: string;
  href: keyof LocationStates | "#" | "/#";
  targetBlank?: boolean;
  children?: NavItemType[];
  type?: "dropdown" | "megaMenu" | "none";
  isNew?: boolean;
}

export interface NavigationItemProps {
  menuItem: NavItemType;
  type: "dropdown" | "megaMenu" | "none";
}

const NavigationItem: FC<NavigationItemProps> = ({ menuItem, type }) => {
  const [menuCurrentHovers, setMenuCurrentHovers] = useState<string[]>([]);
  const history = useHistory()

  const onMouseEnterMenu = (id: string) => {
    setMenuCurrentHovers((state) => [...state, id]);
  };

  const onMouseLeaveMenu = (id: string) => {
    setMenuCurrentHovers((state) => {
      return state.filter((item, index) => {
        return item !== id && index < state.indexOf(id);
      });
    });
  };

  // ===================== MENU MEGAMENU =====================
  const renderMegaMenu = (menu: any) => {
    if (!menu.subCat) {
      return null;
    }

    return (
      <li
        className={`menu-item flex-shrink-0 menu-megamenu menu-megamenu--large`}
      >
        {renderMainItem(menu)}

        <div className="invisible sub-menu absolute top-full inset-x-0 transform z-50">
          <div className="bg-[white] opacity-[.95] shadow-lg">
            <div className="container">
              <div className="flex text-sm py-8">
                <div className="flex w-full flex-row justify-between">
                  {menu.subCat.map((item: any, index: any) => (
                    <div key={index} className="relative px-4">
                      <NavLink
                        exact
                        to={{
                          pathname: '/products',
                          search: `?categories=${item?.name}`,
                        }}
                        strict
                        target={item.targetBlank ? "_blank" : undefined}
                        rel="noopener noreferrer"
                        className="font-normal text-[#0e3d30] hover:font-[700] font-[600] mr-10"
                        onClick={() => history.push({ pathname: '/products/', state: { id: item?._id } })}
                      >
                        {item.name}
                      </NavLink>
                      {index !== menu.subCat.length - 1 && (
                        <div className="absolute top-[10px] right-[40px] border-r border-dotted border-white mx-2 h-6"></div>
                      )}
                    </div>
                  ))}
                </div>


              </div>
            </div>
          </div>
        </div>

      </li>
    );
  };

  const renderMegaMenuNavlink = (item: any) => {
    return (
      <li key={item._id} className={`${item.isNew ? "menuIsNew" : ""}`}>
        <NavLink
          exact
          to={{
            pathname: '/products/', state: { id: item?._id } || undefined,
          }}
          strict
          target={item.targetBlank ? "_blank" : undefined}
          rel="noopener noreferrer"
          className="font-normal text-slate-600 hover:text-black dark:text-slate-400 dark:hover:text-white "
          onClick={() => history.push({ pathname: '/products/', state: { id: item?._id } })}
        >
          {item.name}
        </NavLink>
      </li>
    );
  };

  // ===================== MENU DROPDOW =====================
  const renderDropdownMenu = (menuDropdown: any) => {
    const isHover = menuCurrentHovers.includes(menuDropdown.id);
    return (
      <Popover
        as="li"
        className="menu-item menu-dropdown relative"
        onMouseEnter={() => onMouseEnterMenu(menuDropdown.id)}
        onMouseLeave={() => onMouseLeaveMenu(menuDropdown.id)}
      >
        {() => (
          <>
            <Popover.Button as={Fragment}>
              {renderMainItem(menuDropdown)}
            </Popover.Button>
            <Transition
              as={Fragment}
              show={isHover}
              enter="transition ease-out duration-150"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                static
                className={`sub-menu absolute transform z-10 w-56 top-full left-0 ${menuDropdown?.subCat?.length === 0 && "hidden"}`}
              >
                <ul className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 dark:ring-white dark:ring-opacity-10 text-sm relative bg-white dark:bg-neutral-900 py-4 grid space-y-1">
                  {menuDropdown.subCat?.map((i: any, index: number) => {
                    if (i.subCat?.length !== 0) {
                      return renderDropdownMenuNavlinkHasChild(i);
                    } else {
                      return (
                        <li
                          onClick={() => history.push({ pathname: '/products/', state: { id: i?._id } })}
                          key={i?._id}
                          className="px-2"
                        >
                          {renderDropdownMenuNavlink(i)}
                        </li>
                      );
                    }
                  })}
                </ul>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderDropdownMenuNavlinkHasChild = (item: any) => {
    const isHover = menuCurrentHovers.includes(item._id);
    return (
      <Popover
        as="li"
        key={item._id}
        className="menu-item menu-dropdown relative px-2"
        onMouseEnter={() => onMouseEnterMenu(item._id)}
        onMouseLeave={() => onMouseLeaveMenu(item._id)}
      >
        {() => (
          <>
            <Popover.Button as={Fragment}>
              {renderDropdownMenuNavlink(item)}
            </Popover.Button>
            <Transition
              as={Fragment}
              show={isHover}
              enter="transition ease-out duration-150"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                static
                className="sub-menu absolute z-10 w-56 left-full pl-2 top-0"
              >
                <ul className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 dark:ring-white dark:ring-opacity-10 text-sm relative bg-white dark:bg-neutral-900 py-4 grid space-y-1">
                  {item.subCat?.map((i: any) => {
                    if (i.subCat?.length !== 0) {
                      return renderDropdownMenuNavlinkHasChild(i);
                    } else {
                      return (
                        <li
                          onClick={() => history.push({ pathname: '/products/', state: { id: i?._id } })}
                          key={i._id} className="px-2"
                        >
                          {renderDropdownMenuNavlink(i)}
                        </li>
                      );
                    }
                  })}
                </ul>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderDropdownMenuNavlink = (item: any) => {
    return (
      <NavLink
        exact
        strict
        target={item.targetBlank ? "_blank" : undefined}
        rel="noopener noreferrer"
        onClick={() => history.push({ pathname: '/product-detail/' + item?.slug })}
        className="flex items-center font-normal text-neutral-6000 dark:text-neutral-400 py-2 px-4 rounded-md hover:text-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800 dark:hover:text-neutral-200"
        to={{
          pathname: '/product-detail/' + item?.slug,
          state: { id: item?._id } || undefined,
        }}
        activeClassName="!font-medium !text-neutral-900 dark:!text-neutral-100"
      >
        {item.name}
        {item.subCat?.length !== 0 && (
          <ChevronDownIcon
            className="ml-2 h-4 w-4 text-neutral-500"
            aria-hidden="true"
          />
        )}
      </NavLink>
    );
  };

  // ===================== MENU MAIN MENU =====================
  const renderMainItem = (item: any) => {
    return (
      <div className="h-20 flex-shrink-0 flex items-center">
        <NavLink
          exact
          strict
          target={item.targetBlank ? "_blank" : undefined}
          rel="noopener noreferrer"
          className={`inline-flex items-center text-sm lg:text-[15px] font-medium dark:text-slate-300 font-[500] py-2.5 px-4 xl:px-5 whitespace-nowrap	rounded-full text-[#0e3d30] hover:font-[600]`}
          to={{
            pathname: '/products/', state: { id: item?._id } || undefined,
          }}
          onClick={() => history.push({ pathname: '/products/', state: { id: item?._id } })}
        >
          {item.name}
          {item.subCat?.length !== 0 && (
            <ChevronDownIcon
              style={{ color: COLORS?.navbarArrow }}
              className="ml-1 -mr-1 h-4 w-4 "
              aria-hidden="true"
            />
          )}
        </NavLink>
      </div>
    );
  };

  switch (type) {
    case "dropdown":
      return renderDropdownMenu(menuItem);
    case "megaMenu":
      return renderMegaMenu(menuItem);
    default:
      return (
        <li className="menu-item flex-shrink-0">{renderDropdownMenu(menuItem)}</li>
      )
  }
};

export default NavigationItem;
