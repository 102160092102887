import { Helmet } from "react-helmet";
import SectionHero from "components/SectionHero/SectionHero";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import SectionBeforeFooter from "components/SectionBeforeFooter";
import SectionSliderCategories from "components/SectionSliderCategories/SectionSliderCategories";
import { getAllProducts } from "actions/products";
import { useState, useEffect } from "react";
import SectionSliderProductCardCollection from "components/SectionSliderProductCardCollection";

const PageHome = () => {

  const [allProducts, setAllProducts] = useState([]);

  useEffect(() => {
    getAllProducts().then((res: any) => {
      setAllProducts(res)
    })

  }, [])

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Alcools Bio</title>
      </Helmet>

      {/* CAROUSSEL  */}
      <SectionHero />

      {/* SECTION HOW IT WORK */}
      <SectionHowItWork />

      {/* SLIDER ALL PRODUCTS */}
      {allProducts.length !== 0 &&
        <SectionSliderProductCardCollection
          heading="En ce moment"
          data={allProducts}
        />
      }

      {/* HOME CATEGORY */}
      <SectionSliderCategories
        heading="Découvrez nos gammes de SPIRITUEUX 100% BIOLOGIQUES"
      />
    </div>
  );
}

export default PageHome;
