import { apiClient } from './apiClient'

function getAxeptio() {
    return apiClient().get('/axeptio')
}

function getHotjar() {
    return apiClient().get('/hotjar')
}

function getMondialRelay() {
    return apiClient().get('mondialRelay')
}

export default {
    getAxeptio,
    getHotjar,
    getMondialRelay
}
