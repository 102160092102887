import { deleteShippingAddress, getAllShippingAddress } from "actions/user";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Helmet } from "react-helmet";
import { NotificationManager } from 'react-notifications';

const ShippingAddress = () => {
  const history = useHistory();
  const [allShippingAddress, setAllShippingAddress]: any = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const data = await getAllShippingAddress();
      setAllShippingAddress(data);
    }

    fetchData();
  }, [])

  const handleDeleteShippingAddress = async (id: string) => {
    await dispatch(deleteShippingAddress(id)).then((res: any) => {
      NotificationManager.success("Votre adresse de livraison a été supprimer avec succès !", '');
      setAllShippingAddress(res);
    })
  }

  const handleEditShippingAddress = (id: string, address: any) => {
    history.push({ pathname: '/edit-shipping-address/' + id, state: { address: address } })
  }

  return (
    <div className={`nc-AccountPage`} data-nc-id="AccountPage">
      <Helmet>
        <title>Adresse de livraison | Alcools Bio</title>
      </Helmet>

      <div className="container py-14">
        <div className="flow-root">
          <div className="flex justify-center flex-col lg:px-28">
            <div className="flex flex-row gap-1 font-[500] text-[13px]">
              <a href="/" className="text-primary-6000 hover:underline">Accueil</a>
              <span>{">"}</span>
              <a href="">Mes adresses de livraisons</a>
            </div>
            <div className="mt-16 mb-16">
              <div className="flex w-full justify-between">
                <div className="text-2xl  sm:text-3xl mb-8 font-semibold flex flex-row">
                  <Link to="/customer/overview" className="w-10 h-7 relative top-[.1rem] flex items-center justify-center rounded bg-slate-200 opacity-[.6] hover:opacity-[1] text-neutral-700 nc-shadow-lg ">
                    <span className="text-[20px] font-[500]">{"<"}</span>
                  </Link>
                  <h1 className="ml-4">Mes adresses de livraisons</h1>
                </div>
                <div className="relative top-[-7px]">
                  <ButtonPrimary onClick={() => { history.push('/add-shipping-address') }}>Ajouter une adresse de livraison</ButtonPrimary>
                </div>
              </div>
              {allShippingAddress &&
                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 gap-2">
                  {allShippingAddress?.shippingAddress?.map((it: any, index: number) => (
                    <div className="border border-slate-200 dark:border-slate-700 rounded overflow-hidden z-0 p-4" key={index}>
                      <div className="flex justify-between">
                        <div>{it.address}</div>
                        <button onClick={() => { handleDeleteShippingAddress(it._id) }} className="cursor-pointer w-5 h-5 flex items-center justify-center rounded-full bg-slate-200 dark:bg-slate-900 text-neutral-700 dark:text-slate-200 nc-shadow-lg ">
                          <span className="mt-[.1rem]">-</span>
                        </button>
                      </div>
                      <div>{it.city}, {it.country}</div>
                      <div className="flex justify-end relative z-10 mt-3 font-medium text-primary-6000 hover:text-primary-500 text-sm">
                        <span className="cursor-pointer" onClick={() => handleEditShippingAddress(it._id, it.address)} >Éditer</span>
                      </div>
                    </div>
                  ))}
                </div>
              }
              {Object.keys(allShippingAddress)?.length === 0 &&
                <div className="text-center">Aucune adresse de livraison</div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShippingAddress;
