import { FC, useEffect, useId, useRef, useState } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import ProductCard from "./ProductCard";
import { Product } from "../models/Product"
import { Link, useLocation } from "react-router-dom";

export interface SectionSliderProductCardCollectionProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  headingFontClassName?: string;
  headingClassName?: string;
  subHeading?: string;
  data?: Product[];
}

const SectionSliderProductCardCollection: FC<SectionSliderProductCardCollectionProps> = ({
  className = "",
  heading,
  subHeading,
  data,
}) => {
  const sliderRef = useRef(null);
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");
  const location = useLocation();
  const [hasProductWithCollection, setHasProductWithCollection] = useState();

  useEffect(() => {
    const hasCollection: any = data?.some(item => item?.collections?.includes('64f07ef6e2f6533c10de18a8'));
    setHasProductWithCollection(hasCollection);

    if (!sliderRef.current) {
      return () => { };
    }

    // @ts-ignore
    const OPTIONS: Glide.Options = {
      perView: 4,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: 4 - 1,
        },
        1024: {
          gap: 20,
          perView: 4 - 1,
        },
        768: {
          gap: 20,
          perView: 4 - 2,
        },
        640: {
          gap: 20,
          perView: 1.5,
        },
        500: {
          gap: 20,
          perView: 1.3,
        },
      },
    };

    let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
    slider.mount();
    return () => {
      slider.destroy();
    };
  }, [sliderRef, UNIQUE_CLASS]);

  return (
    <>
      {data?.length !== 0 &&
        <div className={`nc-SectionSliderProductCard ${className} relative  lg:mb-10 lg:mt-12 pb-6 ${location?.pathname === "/" && 'bg-[#134435]'} ${hasProductWithCollection === false ? "hidden" : "block"}`}>
          <div className={`${UNIQUE_CLASS} container ${location?.pathname === "/" && "grass-line"} flow-root`} ref={sliderRef}>
            <Heading isCenter={false} hasNextPrev className="lg:mb-6 mt-10">
              <span className={`mt-12 ${location?.pathname === "/" && "text-[white]"} `}>{heading}</span>
            </Heading>
            <div className="glide mt-6 md:mt-0 lgm:mt-0 xl:mt-0">
              <div data-glide-el="track" className="glide__track">
                <ul className="glide__slides">
                  {data?.filter((item: any) => (item?.disabled === false && item?.collections?.includes('64f07ef6e2f6533c10de18a8'))).map((item: any, index: number) => (
                    <li className="glide__slide m-1" key={index}>
                      <ProductCard data={item} />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default SectionSliderProductCardCollection;
