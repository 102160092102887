import React from "react";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import mastercardImg from "images/mastercard.svg";
import visaImg from "images/visa.svg";
import stripeImg from "images/stripe.svg";
import { COLORS } from "config/theme";
import { useHistory } from "react-router-dom";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "1",
    title: "ALCOOLS BIO",
    menus: [
      { href: "#", label: "VIVANT - 3 route du Molin - 16360 Baignes-Sainte-Radegonde" },
      { href: "#", label: "Service client: 06 58 54 13 87 (du lundi au vendredi de 9h à 17h)" },
      { href: "mailto:contact@alcools-bio.fr", label: "contact@alcools-bio.fr" },
    ],
  },
  {
    id: "2",
    title: "Nos catégories",
    menus: [
      { href: "/products/", label: "Whisky biologique", id: '64be7e14146992cc26c51974' },
      { href: "/products/", label: "Gamme : Portraits Vivant", id: '64be7e9e146992cc26c51975' },
      { href: "/products/", label: " Gin biologique", id: '64be7eee146992cc26c51976' },
      { href: "/products/", label: "Gamme : Le Bestiaire Vivant", id: '64be7f3a146992cc26c51977' },
      { href: "/products/", label: "Rhum biologique", id: '64be7e9e146992cc26c51975' },
      { href: "/products/", label: "Gamme : Envol", id: '64be7e9e146992cc26c51975' },
      { href: "/products/", label: "Cognac biologique", id: '64be7f3a146992cc26c51977' },
      { href: "/products/", label: "Gamme : Cognacs Decroix", id: '64be7f3a146992cc26c51977' },
    ],
  },
  {
    id: "3",
    title: "Légal / Contact",
    menus: [
      { href: "/cgv", label: "CGV" },
      { href: "/mentions-legales", label: "Mentions légales" },
      { href: "/contact", label: "Nous contacter" },
    ],
  },
];

const Footer: React.FC = () => {
  const history = useHistory();

  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    if (menu.id !== "2") {
      return (
        <div key={index} className="text-sm">
          <h2 className="font-semibold xs:mb-3 xs:mt-6 lg:mb-5 font-[700] dark:text-neutral-200">
            {menu.title}
          </h2>
          <ul className="">
            {menu.menus.map((item, index) => (
              <li key={index} className="xs:mt-2 lg:mt-1">
                <a
                  key={index}
                  className={`cursor-pointer ${menu?.id !== "1" && "hover:underline"} font-[400] dark:text-neutral-300 ${item?.label === "contact@alcools-bio.fr" && "underline"}`}
                  onClick={() => { menu?.id === "2" && history.push({ pathname: item?.href, search: `?categories=${item?.label}` }) || menu?.id === "3" && history?.push({ pathname: item?.href }) }}
                  rel="noopener noreferrer"
                >
                  {item.label}
                </a>
              </li>
            ))}
          </ul>
        </div>
      );
    } else {
      return null;
    }
  };


  return (
    <>
      <div className={`nc-Footer relative py-10 lg:pb-24`} style={{ backgroundColor: COLORS.footer, color: COLORS?.footerText }}>
        <div className="container flex grass-line lg:flex-row xs:flex-col justify-between">

          {widgetMenus
            .filter((item) => item.id === "1")
            .map(renderWidgetMenuItem)}

          <div>

            <h2 className="font-semibold xs:mb-3 xs:mt-6 lg:mb-5 font-[700] dark:text-neutral-200">
              Nos catégories
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-2">

              {widgetMenus.find(item => item.title === "Nos catégories")?.menus.map((item, index) => (
                <li key={index} className="list-none text-sm">
                  <a
                    key={index}
                    className={`cursor-pointer hover:underline`}
                    onClick={() => { history.push({ pathname: item?.href, search: `?categories=${item?.label}` })}} 
                  >
                    {item.label}
                  </a>
                </li>
              ))}
            </div>
          </div>

          {widgetMenus
            .filter((item) => item.id === "3")
            .map(renderWidgetMenuItem)}


          <div className="">
            <h2 className="font-semibold xs:mb-3 xs:mt-6 lg:mb-5 font-[700] dark:text-neutral-200">
              Suivez-nous
            </h2>
            <div className="col-span-2 flex items-center md:col-span-3">
              <SocialsList1 className="flex items-center lg:flex-col lg:space-y-3 xs:space-x-1 lg:items-start" />
            </div>
          </div>
        </div>

        <div className="container flex justify-center mt-16 font-semibold">
          L’abus d’alcool est dangereux pour la santé. Consommez avec modération. La vente d'alcool est interdite aux mineurs.
        </div>

        <div className="container mt-[25px]">
          <div className="border-t border-[#BFC6DB] ">
            <div className="mt-[25px] flex grid lg:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 grid-cols-1 justify-between">
              <div>
                <h1 className="text-[13px] mt-[3px]">
                  Services E-commerce développé par <a onClick={() => window.open('https://milango.io/')} className="underline hover:text-[#234336]" href="https://milango.io/">Milango</a>
                </h1>
              </div>
              <div className="flex flex-row lg:justify-end md:justify-end xl:justify-end">
                <h1 className="text-[13px] mt-[4px]">
                  Paiement sécurisé :
                </h1>
                <img width={60} className="ml-[8px]" src={stripeImg} alt="Stripe" />
                <img width={45} className="ml-[4px]" src={mastercardImg} alt="Mastercard" />
                <img width={45} className="ml-[5px]" src={visaImg} alt="Visa" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
