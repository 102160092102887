import React, { FC, useEffect, useState } from "react";
import Logo from "images/logo.png";
import Logo2 from "images/ab.png";
import MenuBar from "shared/MenuBar/MenuBar";
import AvatarDropdown from "./AvatarDropdown";
import CartDropdown from "./CartDropdown";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useHistory } from "react-router-dom";
import NavigationItem from "shared/Navigation/NavigationItem";
import { getAllCategories } from "actions/categories";
import { BORDERS, COLORS } from "config/theme";

export interface MainNavLoggedProps { }

const MainNavLogged: FC<MainNavLoggedProps> = () => {
  const inputRef = React.createRef<HTMLInputElement>();
  const [showSearchForm, setShowSearchForm] = useState(false);
  const [query, setQuery] = useState('');
  const history: any = useHistory();
  const [categories, setCategories] = useState([]);

  const handleRedirectSearch = () => {
    setShowSearchForm(false)
    history.push({ pathname: "search", search: 'q=' + query })
  }

  useEffect(() => {
    getAllCategories().then((result: any) => {
      setCategories(result);
    })
  }, [])

  const renderCategoriesNavBar = (item: any) => Object.keys(item).map((item: any, index) => {
    return (
      <ul key={index} className="nc-Navigation flex justify-center items-center uppercase text-[15px]">
        <li onClick={() => history.push({ pathname: '/page/nos-alcools-bio-français' })} className="text-[#0e3d30] cursor-pointer font-[500] hover:font-[600]">Nos alcools BIO français</li>
        <NavigationItem key={item?._id} menuItem={categories[item]} type="megaMenu" />
        <li className="text-[#0e3d30] cursor-pointer font-[500] hover:font-[600]" onClick={() => history.push({ pathname: '/contact' })}>Nous contacter</li>
      </ul>
    )
  })

  useEffect(() => {
    const handleScrollToTop = () => {
      window.scrollTo(0, 0);
    };

    // Ajoutez un écouteur d'événement pour le changement de route
    const unlisten = history.listen(handleScrollToTop);

    // Nettoyez l'écouteur d'événement lors du démontage du composant.
    return () => {
      unlisten();
    };
  }, [history]);


  const renderMagnifyingGlassIcon = () => {
    return (
      <svg
        width={22}
        height={22}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
          strokeWidth="1.5"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 22L20 20"
          strokeWidth="1.5"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  const renderSearchForm = () => {
    return (
      <div
        className="flex items-center justify-center w-full"
      >
        <div className={`dark:bg-slate-800 flex items-center space-x-1.5 px-5 box-shadow-searchbar w-full border-[1px] border-[${COLORS?.searchInputBorder}]`} style={{ borderRadius: BORDERS.searchInput }}>
          {renderMagnifyingGlassIcon()}
          <input
            onKeyUp={(e) => e.key === 'Enter' && handleRedirectSearch()}
            ref={inputRef}
            onChange={(e) => setQuery(e.target.value)}
            type="text"
            placeholder="Rechercher un produit..."
            className={`border-none bg-transparent focus:outline-none focus:ring-0 w-full text-base text-[white] placeholder:text-[white]`}
            autoFocus
          />
          <button onClick={() => setShowSearchForm(false)}>
            <XMarkIcon className="w-5 h-5 text-[white]" />
          </button>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="h-20 flex justify-between">
        <div className="flex items-center lg:hidden">
          <MenuBar />
        </div>


        <div className={`flex items-center justify-center`}>
          <img onClick={() => history.push({ pathname: '/' })} src={Logo} className="w-[58px] cursor-pointer" />
          <img onClick={() => history.push({ pathname: '/' })} src={Logo2} className="w-[58px] ml-3 cursor-pointer" />
        </div>

        {showSearchForm
          ? <div className="flex-[2] hidden lg:flex justify-center mx-4">
            {renderSearchForm()}
          </div>
          : <div className="hidden lg:flex lg:justify-center mx-4">
            {renderCategoriesNavBar(categories)}</div>
        }

        <div className="flex items-center justify-end text-slate-700 dark:text-slate-100">
          <CartDropdown />
          <AvatarDropdown />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={`nc-MainNavLogged z-10`} style={{ backgroundColor: COLORS?.navbar }}>
        <div className="container">{renderContent()}</div>
      </div>
    </>
  );
};

export default MainNavLogged;
