export const COLORS = {


    preHeader: "#F7ECDB",
    preHeaderText: "#234336",

    // NAVBAR 
    navbar: 'white',
    navbarText: '#FFFFFF',
    navbarTextHover: '#234336',
    navbarArrow: '#0e3d30',


    // SLIDER
    sliderText: 'red',
    sliderBg: 'white',
    sliderBgText: "white",

    // FOOTER
    footer: '#134435',
    footerText: '#FFFFFF',


    // BUTTON
    buttonPrimary: '#234336',
    buttonSecondary: '#234336',
    buttonThird: '#0e3d30',
    filterBtn: '#234336',

    // INPUT
    searchInputBorder: 'white',

    heading: '#234336',
    text: '#234336',
    headingCaroussel: "#603914",
    paragraphCaroussel: "#234336",
    placeholderSearchInput: 'white',
    backgroundHowItWork: '',
    loadingSpinner: '',
}

export const BORDERS = {
    buttonPrimary: '0px',
    buttonSecondary: '0px',
    buttonVariant: '30px',
    searchInput: '999px',
    inputQuantityNumber: '0px',
    buttonThird: '3px',

    input: ''
}
